<template>
  <div>
    <h3>
      {{ $t("plainText") }} <span class="text-muted">{{ count }}</span>
    </h3>
    <p class="text-muted">{{ $t("plainTextDescription") }}</p>
    <Alert :alert="alert" />
    <BaseTable
      i18n
      :loading="isLoading"
      :items="items"
      :count="count"
      :limit="this.limit"
      @change:search="SET_SEARCH"
      :page="page"
      @change:page="SET_PAGINATION"
      :searchPlaceholder="$t('searchCustomerPlaceholder')"
      :pages="pages"
      :headers="headers"
      noSearch
      noCaption
    >
      <template #createdDate="{ item }">
        {{ setDate(item.createdDate) }}
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "../../base/Table/index.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faPhone,
  faEnvelope,
  faCalendar,
  faUser,
  faCircleUser,
  faUserCheck,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import { getLog } from "../../../services/modules/Log";
import Alert from "../../base/alert/Alert.vue";

const year = new Date().getFullYear() - 1;

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      faEye,
      faPhone,
      faUser,
      faCalendar,
      faUndo,
      faEnvelope,
      faCircleUser,
      faUserCheck,
      search: "",
      status: -1,
      showAssignmentAdmin: false,
      paymentList: [
        { value: "", text: "allStatus" },
        { value: false, text: "paymentNoSucceedUser" },
        { value: true, text: "paymentSucceedUser" },
      ],
      headers: [
        { title: "plainText", value: "plainText", col: 12, md: 6, lg: 6 },
        { title: "createdDate", value: "createdDate", col: 12, md: 6, lg: 6 },
      ],
      count: 0,
      page: 1,
      items: [],
      isLoading: true,
      activeUpdateStatus: -1,
      admins: [],
      limit: 10,
      admin: {},
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      filterAdmin: null,
      isPayment: "",
      searchTimeout: -1,
      years: [
        { year: year, hasFile: false },
        { year: year - 1, hasFile: false },
        { year: year - 2, hasFile: false },
        { year: year - 3, hasFile: false },
        { year: year - 4, hasFile: false },
      ],
      taxYear: "",
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  methods: {
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getLog(this.page, this.search, this.status);
    },
    async getLog() {
      this.isLoading = true;
      const response = await getLog(this.page, 10);
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else {
        this.items = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(q.page)) this.page = Number(q.page);
    if (q.status) this.isPayment = Boolean(q.status) || "";
    if (q.search) this.search = q.search;
    this.getLog();
  },
};
</script>

<style lang="scss">
.account-settings {
  .lang-select-box {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
.status-option-dropdown {
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 11;
  &__button {
    cursor: pointer;
  }
}

.select-box {
  border-radius: 20px !important;
  font-size: 12px;
}

.o-table__body__item {
  &:hover {
    transition: none !important;
    transform: none !important;
    color: #000;
  }
}
.lang-select-box {
  .vs__dropdown-toggle {
    border: none;
  }
}
.vs__dropdown-toggle {
  border-radius: 20px;
}
.find-admin {
  .vs__dropdown-toggle {
    padding: 4px 10px;
  }
  .vs__clear {
    display: none;
  }
}
</style>
