<template>
  <div>
    <Logs></Logs>
  </div>
</template>

<script>
import Logs from "@/components/admin/logs";
export default {
  components: { Logs },
};
</script>

<style></style>
